<template>
  <div class="tile is-parent is-half">
    <article class="tile is-child box detail-page-tile">
      <div class="columns is-gapless">
        <div class="column">
          <p class="title">Invoice Items</p>
          <p class="subtitle">Manage invoice items</p>
        </div>
        <div class="column">
          <div v-if="!gstPaid && !readOnlyView"
            class="field is-pulled-right">
            <p class="buttons">
              <button class="button is-primary tooltip is-tooltip-topright"
                data-tooltip="Add new item"
                @click="toggleQuickItemList()">
                <span class="icon">
                  <i class="mdi"
                    :class="{ 'mdi-plus' : !isQuickItemListActive, 'mdi-minus' : isQuickItemListActive }" />
                </span>
                <span>{{ isQuickItemListActive ? 'Close' : 'Add' }}</span>
              </button>
              <button class="button is-primary tooltip is-tooltip-topright"
                data-tooltip="Add blank item"
                @click="addBlankItem">
                <span class="icon">
                  <i class="mdi mdi-shape-square-plus" />
                </span>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div v-if="false"
        class="field">
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field-body">
              <div class="field has-addons">
                <div class="control has-icons-left has-icons-right">
                  <input class="input"
                    type="text"
                    placeholder="Start typing to filter">
                  <span class="icon is-left">
                    <i class="mdi mdi-magnify mdi-18px" />
                  </span>
                </div>
                <div class="control">
                  <a class="button is-primary is-outlined">
                    <span class="icon">
                      <i class="mdi mdi-close mdi-18px" />>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <quickinvoice-item-list v-show="isQuickItemListActive"
        v-model="innerValue.items"
        :invoice-id="innerValue.invoiceId"
        :invoice-gl-account="innerValue.invoiceGlCode"
        :gst-rate="innerValue.gstRate" />
      <div v-show="isQuickItemListActive"
        class="is-divider" />
      <div class="tags has-addons">
        <span class="tag is-dark is-medium">Unit Price</span>
        <span v-if="readOnlyView"
          class="tag is-link is-medium"
          :class="[ innerValue.isGstOn ? 'is-success' : 'is-info' ]">{{ innerValue.isGstOn ? 'includes' : 'excludes' }} GST</span>
        <a v-else
          class="tag is-link is-medium is-disabled"
          :class="[ innerValue.isGstOn ? 'is-success' : 'is-info']"
          @click="toggleGst">{{ innerValue.isGstOn ? 'includes' : 'excludes' }} GST</a>
      </div>
      <!-- <span>Unit Price <span class="has-text-weight-bold">{{innerValue.isGstOn ? 'include' : 'exclude'}}</span> GST </span> -->
      <!-- <div class="field">
        <input v-if="!gstPaid"
          id="isGstOn"
          name="isGstOn"
          v-model="innerValue.isGstOn"
          type="checkbox"
          class="switch is-success is-rounded is-small">
        <label for="isGstOn"
          data-has-text>Unit Price
          <span class="has-text-weight-bold is-italic">{{innerValue.isGstOn ? 'includes' : 'excludes'}}</span> GST</label>
      </div> -->
      <table class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>Description</th>
            <th class="has-text-right">Qty</th>
            <th class="has-text-right">
              Unit Price
              <a class="tooltip"
                :data-tooltip="innerValue.isGstOn ? 'Unit Price incl. GST' : 'Unit Price excl. GST'"
                @click="toggleGst">
                <span class="icon is-small">
                  <i class="mdi mdi-18px"
                    :class="gstToggleClass" />
                </span>
              </a>
            </th>
            <th class="has-text-right">Inc. GST</th>
            <th class="has-text-right">GST</th>
            <th>G/L Account</th>
            <th v-if="!gstPaid && !readOnlyView" />
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th class="has-text-right"
              colspan="3">Totals</th>
            <th class="has-text-right">{{ innerValue.totalIncGst | formatCurrency($userInfo.locale) }}</th>
            <th class="has-text-right">{{ innerValue.gst | formatCurrency($userInfo.locale) }}</th>
            <th colspan="2" />
          </tr>
        </tfoot>
        <tbody v-if="gstPaid || readOnlyView">
          <tr v-for="(item, index) in activeItems"
            :key="item.invoiceItemId">
            <td>{{ item.invoiceItemDesc }}</td>
            <td class="has-text-right">{{ item.quantity }}</td>
            <td class="has-text-right">{{ item.unitPrice | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ item.totalIncGst | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ item.gst | formatNumber($userInfo.locale) }}</td>
            <td v-if="selectedItemAccounts.length > 0"
              class="is-nowrap">{{ selectedItemAccounts[index].displayName }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(item, index) in activeItems"
            :key="item.invoiceItemId">
            <td>
              <textarea class="textarea"
                :class="{ 'is-danger' : !$v.entity.items.$each[index].invoiceItemDesc.required }"
                v-focus-inserted
                placeholder="Item Description"
                rows="2"
                v-model="item.invoiceItemDesc" />
            </td>
            <td class="has-text-right quantity-input">
              <vue-numeric class="input has-text-right"
                :min="0"
                :max="999"
                :precision="0"
                v-model.number="item.quantity"
                @input="updateItem(item)" />
            </td>
            <td class="has-text-right money-input">
              <vue-numeric v-if="value.isGstOn"
                class="input has-text-right"
                v-model.number="item.unitPriceIncGst"
                :minus="true"
                :precision="2"
                @input="updateItem(item)" />
              <vue-numeric v-else
                class="input has-text-right"
                v-model.number="item.unitPrice"
                :minus="true"
                :precision="2"
                @input="updateItem(item)" />
            </td>
            <td class="has-text-right money-input">
              <vue-numeric class="input has-text-right is-static"
                :value="item.totalIncGst"
                :precision="2"
                readonly
                tabindex="-1" />
            </td>
            <td class="has-text-right money-input">
              <vue-numeric class="input has-text-right"
                :minus="true"
                :precision="2"
                v-model.number="item.gst"
                @input="updateGstTotal(item)" />
            </td>
            <td class="account-input">
              <multiselect v-if="glAccountListCombo && selectedItemAccounts.length > 0"
                v-model="selectedItemAccounts[index]"
                :options="glAccountListCombo"
                placeholder="Select account"
                label="displayName"
                track-by="accountNo"
                deselect-label="Can't remove this value"
                :show-labels="false"
                :allow-empty="false">
                <span class="has-text-danger"
                  slot="noResult">G/L account not found.</span>
              </multiselect>
            </td>
            <td class="has-vertical-middle has-text-centered is-content-width">
              <a class="button is-danger is-small is-inverted"
                @click="deleteItem(item, index)">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  </div>
</template>

<script>
import QuickInvoiceService from './QuickInvoiceService'
import Multiselect from 'vue-multiselect'
import QuickInvoiceItemList from './QuickInvoiceItemList'
import _debounce from 'lodash.debounce'
import { FocusInserted } from '@/components/directives'
import { calcTotalExGst, calcGst, calcBeforeGst, calcGstFromInclusive, roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import VueNumeric from '@/components/VueNumeric'
import { EventHubTypes } from '@/enums'
import { InvoiceItemModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'QuickinvoiceItems',
  inject: ['$vv'],
  components: {
    [QuickInvoiceItemList.name]: QuickInvoiceItemList,
    Multiselect,
    VueNumeric
  },
  directives: {
    FocusInserted
  },
  filters: {
    formatGlDisplayName(value, list) {
      if (list.length > 0) {
        const glAccount = list.find((gl) => gl.accountNo === value)
        return glAccount.displayName
      }
    }
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    gstPaid: {
      type: Boolean,
      default: false
    },
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      glAccountListCombo: [],
      isQuickItemListActive: false,
      selectedItemAccounts: []
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    activeItems() {
      return this.innerValue.items.filter((i) => !i.isDeleted)
    },
    gst() {
      return roundAwayFromZero(
        this.activeItems.reduce(function (total, item) {
          return total + item.gst
        }, 0),
        2
      )
    },
    totalExGst() {
      return roundAwayFromZero(
        this.activeItems.reduce(function (total, item) {
          return total + item.totalExGst
        }, 0),
        2
      )
    },
    totalIncGst() {
      if (this.innerValue.isGstOn) {
        return roundAwayFromZero(
          this.activeItems.reduce(function (total, item) {
            return total + item.totalIncGst
          }, 0),
          2
        )
      } else {
        return roundAwayFromZero(this.totalExGst + this.gst, 2)
      }
    },
    gstToggleClass() {
      return this.innerValue.isGstOn ? 'mdi-currency-usd' : 'mdi-currency-usd-off'
    }
  },
  watch: {
    // value: {
    //   handler: function(newVal, oldVal) {
    //     this.$emit('input', newVal)
    //   },
    //   deep: true
    // },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    'innerValue.items': {
      // item addition/deletion watcher
      handler: function (newVal, oldVal) {
        this.generateSelectedAccounts()
        this.innerValue.totalExGst = this.totalExGst
        this.innerValue.gst = this.gst
        this.innerValue.totalIncGst = this.totalIncGst
      },
      deep: true
    },
    selectedItemAccounts: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          const vm = this
          newVal.forEach(function (account, index) {
            if (account) {
              vm.innerValue.items[index].invoiceGlCode = account.accountNo
              vm.innerValue.items[index].glCategory = account.glCategory
            }
          })
        }
      }
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  async created() {
    this.glAccountListCombo = await QuickInvoiceService.getGlAccountDropdown()
    this.generateSelectedAccounts()
  },
  mounted() {
    this.$v.entity.items.$touch()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })

    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    toggleQuickItemList() {
      this.isQuickItemListActive = !this.isQuickItemListActive
    },
    generateSelectedAccounts() {
      const vm = this
      vm.selectedItemAccounts = []
      this.innerValue.items
        .filter((i) => !i.isDeleted)
        .forEach(function (item) {
          vm.selectedItemAccounts.push(vm.glAccountListCombo.find((g) => g.accountNo === item.invoiceGlCode))
        })
    },
    deleteItem(item, index) {
      if (item.isNew) {
        const itemIndex = this.innerValue.items
          .map(function (obj) {
            return obj.invoiceItemId
          })
          .indexOf(item.invoiceItemId)
        if (itemIndex >= 0) {
          this.innerValue.items.splice(itemIndex, 1)
        }
      } else {
        item.isDeleted = true
      }
    },
    updateItem: _debounce(function (item) {
      if (this.innerValue.isGstOn) {
        item.gst = calcGstFromInclusive(item.unitPriceIncGst, this.innerValue.gstRate) * item.quantity
        item.unitPrice = calcBeforeGst(item.unitPriceIncGst, this.innerValue.gstRate)
        item.totalExGst = calcBeforeGst(item.unitPriceIncGst, this.innerValue.gstRate) * item.quantity
        item.totalIncGst = roundAwayFromZero(item.totalExGst + item.gst)
      } else {
        item.totalExGst = calcTotalExGst(item.quantity, item.unitPrice)
        item.gst = calcGst(item.quantity, item.unitPrice, this.innerValue.gstRate)
        item.totalIncGst = roundAwayFromZero(item.totalExGst + item.gst, 2)
        item.unitPriceIncGst = roundAwayFromZero(item.totalIncGst / item.quantity)
      }
    }, 300),
    updateGstTotal: _debounce(function (item) {
      if (this.innerValue.isGstOn) {
        item.totalIncGst = roundAwayFromZero(item.unitPriceIncGst * item.quantity)
      } else {
        item.totalIncGst = roundAwayFromZero(item.totalExGst + item.gst, 2)
      }
    }, 300),
    toggleGst() {
      if (!this.gstPaid) {
        this.innerValue.isGstOn = !this.innerValue.isGstOn
      }
    },
    addBlankItem() {
      const newItem = new InvoiceItemModel(this.$userInfo.companyId, this.invoiceId, '41210')
      // var newItem = {
      //   companyId: this.$userInfo.companyId,
      //   costCentre: null,
      //   deleted: false,
      //   invoiceGlCode: '41210', // TODO: Should not be hard coded
      //   glCategory: '',
      //   invoiceId: this.invoiceId,
      //   invoiceItemDesc: '',
      //   invoiceItemId: Guid.newGuid(),
      //   isDeleted: false,
      //   isNew: true,
      //   quantity: 1,
      //   quoteItemId: null,
      //   totalExGst: 0,
      //   gst: 0,
      //   totalIncGst: 0,
      //   unitPrice: 0,
      //   unitPriceIncGst: 0
      // }
      this.innerValue.items.push(newItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.money-input {
  max-width: 6.5em;
}
.quantity-input {
  max-width: 3.5em;
}
.description-input {
  width: 18em;
}
.account-input {
  width: 18em;
}
</style>
